<template>
  <SubNav
    class="subnav-position"
    :selected="'rentroll'"
    :buildingId="buildingId"
  />
  <main>
    <div class="error">{{ errorDoc }}</div>
    <div v-if="rentRoll && building">
      <div class="d-flex flex-row-reverse">
        <router-link
          class="btn btn-primary"
          :to="{ name: 'CreateLease', params: { buildingId: buildingId } }"
        >
          New Lease</router-link
        >
      </div>
      <h4>{{ building.name }}</h4>
      <table class="table table-responsive">
        <thead>
          <tr>
            <th scope="col">Tenant</th>
            <th scope="col">Suite</th>
            <th scope="col">Usqft</th>
            <th scope="col">$/Usqft</th>
            <th scope="col">Rsqft</th>
            <th scope="col">$/Rsqft</th>
            <th scope="col">Monthly</th>
            <th scope="col">Other$</th>
            <th scope="col">Annual</th>
            <th scope="col">Expiration</th>
            <th scope="col">Escalator</th>
            <th scope="col">Date</th>
            <th scope="col">Notice</th>
            <th scope="col">Notes</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="lease in leaseList" :key="lease.id">
            <td>
              <router-link
                :to="{
                  name: 'EditLease',
                  params: { buildingId: buildingId, leaseId: lease.id }
                }"
              >
                {{ lease.tenant }}
              </router-link>
            </td>
            <td>{{ lease.suite }}</td>
            <td>
              {{
                lease.usqft.toLocaleString("en-US", {
                  maximumFractionDigits: 0
                })
              }}
            </td>
            <td>
              {{
                lease.usqftPrice.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD"
                })
              }}
            </td>
            <td>
              {{
                lease.rsqft.toLocaleString("en-US", {
                  maximumFractionDigits: 0
                })
              }}
            </td>
            <td>
              {{
                lease.rsqftPrice.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD"
                })
              }}
            </td>
            <td>
              {{
                lease.rent.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD"
                })
              }}
            </td>
            <td>
              {{
                lease.otherCosts.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD"
                })
              }}
            </td>
            <td>
              {{
                lease.rentAnnual.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD"
                })
              }}
            </td>
            <td>{{ lease.expirationDate }}</td>
            <td>{{ lease.escalator }}</td>
            <td>{{ lease.escalatorDate }}</td>
            <td>{{ lease.noticeDate }}</td>
            <td>{{ lease.notes }}</td>
          </tr>
          <tr>
            <td>Totals</td>
            <td></td>
            <td>
              {{
                usqftTotal.toLocaleString("en-US", {
                  maximumFractionDigits: 0
                })
              }}
            </td>
            <td>
              {{
                usqftAverage.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD"
                })
              }}
            </td>
            <td>
              {{
                rsqftTotal.toLocaleString("en-US", {
                  maximumFractionDigits: 0
                })
              }}
            </td>
            <td>
              {{
                rsqftAverage.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD"
                })
              }}
            </td>
            <td>
              {{
                monthlyRent.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD"
                })
              }}
            </td>
            <td>
              {{
                monthlyOther.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD"
                })
              }}
            </td>
            <td>
              {{
                annualTotal.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD"
                })
              }}
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>Vacant</td>
            <td></td>
            <td>
              {{
                usqftVacant.toLocaleString("en-US", {
                  maximumFractionDigits: 0
                })
              }}
            </td>
            <td></td>
            <td>
              {{
                rsqftVacant.toLocaleString("en-US", {
                  maximumFractionDigits: 0
                })
              }}
            </td>
            <td></td>
            <td>
              {{
                monthlyRentVacant.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD"
                })
              }}
            </td>
            <td></td>
            <td>
              {{
                annualRentVacant.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD"
                })
              }}
            </td>
            <td>{{vacancyRate.toLocaleString("en-US",{style: "percent", maximumFractionDigits: 1})}}</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>
      <div class="container">
        <div class="row">
          <div class="col"></div>
          <div class="col"></div>
          <div class="col"></div>
          <div class="col"></div>
          <div class="col"></div>
        </div>
      </div>
      <div class="form-outline mb-4">
        <label>Maximum Building Usable Square Feet: {{building.maxUsqft.toLocaleString("en-US", {
                  maximumFractionDigits: 0
                })}}</label>
      </div>
      <div class="form-outline mb-4">
        <label>Rent Roll Title</label>
        <input
          type="text"
          required
          v-model="rentRollTitle"
          class="form-control"
        />
      </div>
      <div class="form-outline mb-4">
        <label>Rent Roll Message</label>
        <input
          type="text"
          required
          v-model="rentRollNotes"
          class="form-control"
        />
      </div>
      <div class="col">
        <div v-if="isPending">
          <button class="btn btn-secondary" disabled>
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Sending...</span>
            </div>
          </button>
        </div>
        <div v-else>
          <span class="btn btn-primary" @click="sendRentRoll">
            Send Rent Roll
          </span>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import SubNav from "@/components/navigation/SubNav.vue";
import getDocument from "@/composables/getDocument";
import getCollection from "@/composables/getCollection";
import { ref } from "@vue/reactivity";
import { projectFunctions } from "@/firebase/config";
import getUser from "@/composables/getUser";
import { format } from "date-fns";
import { computed } from "@vue/runtime-core";

export default {
  props: ["buildingId"],
  components: { SubNav },
  setup(props) {
    const isPending = ref(false);
    const rentRollTitle = ref("Rent Roll");
    const rentRollNotes = ref("Please find the rent roll attached.");
    const { user } = getUser();
    const { error: errorDoc, document: rentRoll } = getDocument(
      "rentrolls",
      props.buildingId
    );

    const { error: errorBuilding, document: building } = getDocument(
      "buildings",
      props.buildingId
    );

    const sendRentRollMessage = projectFunctions.httpsCallable("sendRentRoll");

    const { error: errorLease, documents: leaseList } = getCollection(
      `rentrolls/${props.buildingId}/leaseList`, 
      null,
      [["tenant","asc"]]
    );

    const monthlyRent = computed(() => {
      if (leaseList.value) {
        return leaseList.value
          .map(({ rent }) => rent)
          .reduce((a, b) => a + b, 0);
      } else {
        console.log("No lease list found");
        return 0;
      }
    });

    const monthlyOther = computed(() => {
      if (leaseList.value) {
        return leaseList.value
          .map(({ otherCosts }) => otherCosts)
          .reduce((a, b) => a + b, 0);
      } else {
        console.log("No lease list found");
        return 0;
      }
    });

    const usqftTotal = computed(() => {
      if (leaseList.value) {
        return leaseList.value
          .map(({ usqft }) => usqft)
          .reduce((a, b) => a + b, 0);
      } else {
        console.log("No lease list found");
        return 0;
      }
    });

    const usqftVacant = computed(() => {
      if (usqftTotal.value && building.value) {
        return building.value.maxUsqft - usqftTotal.value;
      } else {
        return 0;
      }
    })

    const usqftAverage = computed(() => {
      if (annualTotal.value && usqftTotal) {
        return annualTotal.value / usqftTotal.value;
      } else {
        return 0;
      }
    });

    const rsqftTotal = computed(() => {
      if (leaseList.value) {
        return leaseList.value
          .map(({ rsqft }) => rsqft)
          .reduce((a, b) => a + b, 0);
      } else {
        console.log("No lease list found");
        return 0;
      }
    });

    const rsqftVacant = computed(() => {
      if (usqftVacant.value) {
        return usqftVacant.value * 1.11;
      } else {
        return 0;
      }
    })

    const rsqftAverage = computed(() => {
      if (annualTotal.value && rsqftTotal) {
        return annualTotal.value / rsqftTotal.value;
      } else {
        return 0;
      }
    });

    const monthlyRentVacant = computed(() => {
      if (rsqftVacant.value && rsqftAverage.value) {
        return (rsqftVacant.value * rsqftAverage.value) / 12;
      } else {
        return 0;
      }
    })

    const annualRentVacant = computed(() => {
      if (monthlyRentVacant.value) {
        return monthlyRentVacant.value * 12;
      } else {
        return 0;
      }
    })

    const annualTotal = computed(() => {
      if (leaseList.value) {
        return leaseList.value
          .map(({ rentAnnual }) => rentAnnual)
          .reduce((a, b) => a + b, 0);
      } else {
        console.log("No lease list found");
        return 0;
      }
    });

    const vacancyRate = computed (() => {
      if (building.value && usqftVacant.value) {
        return usqftVacant.value / building.value.maxUsqft;
      } else {
        return 0;
      }
    })

    const sendRentRoll = async () => {
      try {
        isPending.value = true;
        console.log(`User: ${user.value.displayName} ${building.value.name}`);
        let rentRollMessage = {
          fromName: user.value.displayName,
          fromEmail: user.value.email,
          rentRollTitle: rentRollTitle.value,
          rentRollNotes: rentRollNotes.value,
          sendToName: "Freddy Tester",
          sendToEmail: "michael@willowoaks.com",
          rentRollId: props.buildingId,
          buildingName: building.value.name,
          rentRollDate: format(new Date(), "MMM d, uuuu HH:mm:ss"),
          monthlyRent: monthlyRent.value,
          monthlyOther: monthlyOther.value,
          usqftTotal: usqftTotal.value,
          usqftAverage: usqftAverage.value,
          rsqftTotal: rsqftTotal.value,
          rsqftAverage: rsqftAverage.value,
          annualTotal: annualTotal.value,
          usqftVacant: usqftVacant.value,
          rsqftVacant: rsqftVacant.value,
          monthlyRentVacant: monthlyRentVacant.value,
          annualRentVacant: annualRentVacant.value,
          vacancyRate: vacancyRate.value
        };
        const sendResult = await sendRentRollMessage(rentRollMessage);
        console.log(sendResult.data);
        if (sendResult.data) {
          errorDoc.value = sendResult.data.error;
        } else {
          errorDoc.value = "Rent Roll Sent";
        }
      } catch (err) {
        console.log(`Error: ${err}`);
        errorDoc.value = err;
      }
      isPending.value = false;
    };

    return {
      errorDoc,
      rentRoll,
      building,
      errorLease,
      errorBuilding,
      leaseList,
      isPending,
      sendRentRoll,
      rentRollTitle,
      rentRollNotes,
      monthlyRent,
      monthlyOther,
      usqftTotal,
      usqftVacant,
      usqftAverage,
      rsqftTotal,
      rsqftVacant,
      rsqftAverage,
      monthlyRentVacant,
      annualRentVacant,
      annualTotal,
      vacancyRate
    };
  }
};
</script>

<style>
</style>