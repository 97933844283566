<template>
  <div class="sub-menu justify-content-center navbar-light">
    <ul class="nav nav-pills flex-column flex-sm-row">
      <li class="nav-item">
        <router-link
          :to="{ name: 'BuildingDetails', params: { buildingId: buildingId } }"
          class="flex-sm-fill text-sm-center nav-link"
          :class="{ active: selected === 'details' }"
          >Details</router-link
        >
      </li>
      <li class="nav-item">
        <router-link
          :to="{ name: 'Spaces', params: { buildingId: buildingId } }"
          class="flex-sm-fill text-sm-center nav-link"
          :class="{ active: selected === 'spaces' }"
          >Spaces</router-link
        >
      </li>
      <li class="nav-item">
        <router-link
          :to="{ name: 'BuildingTenants', params: { buildingId: buildingId } }"
          class="flex-sm-fill text-sm-center nav-link"
          :class="{ active: selected === 'tenants' }"
          >Tenants</router-link
        >
      </li>
      <li class="nav-item">
        <router-link
          :to="{ name: 'RentRoll', params: { buildingId: buildingId } }"
          class="flex-sm-fill text-sm-center nav-link"
          :class="{ active: selected === 'rentroll' }"
          >Rent Roll</router-link
        >
      </li>
    </ul>
  </div>
</template>


<script>
export default {
  props: ["selected", "buildingId"]
};
</script>

<style>
.sub-menu {
  display: flex;
  top: 3.8em;
  background-color: white;
}
</style>